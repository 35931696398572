import { useEffect } from 'react'

import { useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'

import { getSentryDsn, getSentryEnvironment } from '~/utils'

export const disallowedSentryRoutes = [
  'routes/api/healthcheck',
  'socket',
  'favicon.ico',
]

export function SentryInit() {
  if (['development', 'local'].includes(process.env.NODE_ENV)) return
  Sentry.init({
    dsn: getSentryDsn(),
    tracesSampler: (samplingContext) => {
      if (
        disallowedSentryRoutes.includes(samplingContext.transactionContext.name)
      ) {
        // Drop this transaction, by setting its sample rate to 0%
        return 0
      } else {
        // Default sample rate for all others (replaces tracesSampleRate)
        return 1
      }
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    environment: getSentryEnvironment(true),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches
        ),
      }),
      new Sentry.Replay(),
    ],
  })
}
